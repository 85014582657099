import Masonry from "react-masonry-css";
import { useMatch, useLocation } from "react-router-dom";

import { useProjects, useCarousel } from "../utils/hooks";
import ProjectCard from "../components/ProjectCard";
import Carousel from "../components/Carousel";
import css from "./ProjectsIndex.module.css";

const cols = {
  default: 3,
  1100: 2,
  600: 1,
};

const ArtIndex = () => {
  const { pathname } = useLocation();
  let section = pathname.replace(/\//g, "");

  const projects = useProjects(section);
  const carousel = useCarousel(section);
  return (
    <section className={css.root}>
      <Carousel images={carousel} />
      <Masonry
        breakpointCols={cols}
        className={css.grid}
        columnClassName={css.gridCol}
      >
        {projects.map((project, index) => {
          return (
            <ProjectCard key={index} className={css.project} {...project} />
          );
        })}
      </Masonry>
    </section>
  );
};

export default ArtIndex;
