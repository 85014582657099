import cn from "classnames";

export const splitTitle = (title, capClass) => {
  return title.split("").map((l, i) => {
    const isCap = /[A-Z]/.test(l);
    return (
      <span key={i} className={cn({ [capClass]: isCap })}>
        {l.toUpperCase()}
      </span>
    );
  });
};
