import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import css from "./Image.module.css";

const toHex = (color) => {
  return color.indexOf("#") >= 0 ? color : `#${color}`;
};

const Img = (props) => {
  const [loaded, setLoaded] = useState();
  const {
    src,
    fit,
    alt,
    color,
    width,
    height,
    ratio: _ratio,
    sizes,
    srcSetSizes,
    vimeo,
    className,
  } = props;
  const ratio = _ratio || width / height;
  const isGif = /.gif$/.test(src);
  const ref = useRef();
  useEffect(() => {
    const img = ref.current.querySelector("." + css.image);
    if (!img) return;

    const onLoad = () => setLoaded(true);

    img.addEventListener("load", onLoad);
    return () => {
      img.removeEventListener("load", onLoad);
    };
  }, []);

  return (
    <div
      className={classnames(css.root, className, {
        [css.loaded]: loaded,
        [css.fit]: fit,
      })}
      ref={ref}
      style={{
        paddingBottom: `${100 / ratio}%`,
      }}
    >
      <img
        className={classnames(css.image)}
        src={isGif ? src : sizes.small}
        srcSet={isGif ? null : `${sizes.medium} 750w, ${sizes.large} 1200w`}
        sizes={srcSetSizes}
        alt={alt}
      />

      <div
        className={classnames(css.placeholder)}
        style={{
          backgroundColor: toHex(color || "#000000"),
        }}
      />
    </div>
  );
};

export default Img;
