import { useAbout } from "../utils/hooks";
import Clock from "../components/Clock";
import Image from "../components/Image";
import css from "./About.module.css";

const About = () => {
  const about = useAbout();

  return (
    <article className={css.root}>
      <div className={css.row}>
        <section className={css.bio}>
          <div
            className={css.bioText}
            dangerouslySetInnerHTML={{ __html: about.bio }}
          />
          <a
            className={css.button}
            href={`mailto: ${about.email}`}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            Write me to <strong>{about.email}</strong>
          </a>
        </section>
        <section className={css.resume}>
          <div
            className={css.resumeContent}
            dangerouslySetInnerHTML={{ __html: about.resume }}
          />
        </section>
      </div>
      <div className={css.row}>
        <section className={css.clocks}>
          {about.clocks.map((clock, index) => {
            return <Clock timezone={clock.timezone} city={clock.city} />;
          })}
        </section>
        <figure className={css.photo}>
          <Image
            {...about.images[0]}
            srcSetSizes="(max-width: 900px) 40vw, 90vw"
          />
        </figure>
        <div className={css.spacer} />
      </div>
    </article>
  );
};

export default About;
