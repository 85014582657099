import { Link, NavLink } from "react-router-dom";
import { Head, Arrows } from "../icons/Icons";

import { splitTitle } from "../utils";

import css from "./Header.module.css";

export const Header = () => {
  const active = ({ isActive }) => (isActive ? css.active : undefined);

  return (
    <header className={css.root}>
      <h1 className={css.studio}>
        <Link to="/">{splitTitle("SIMÓN SEPÚLVEDA STUDIO")}</Link>
      </h1>
      <nav className={css.nav}>
        {false && <Head className={css.icon} />}
        {false && <Arrows className={css.icon} />}
        <NavLink className={active} to="/design">
          Design
        </NavLink>
        <NavLink className={active} to="/art">
          Art
        </NavLink>
        <NavLink className={active} to="/about">
          About
        </NavLink>
      </nav>
    </header>
  );
};

export default Header;
