import React, { useEffect } from "react";
import cn from "classnames";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.css";
import Image from "./Image";
import Video from "./Video";

import css from "./Carousel.module.css";

export const Carousel = ({ images }) => {
  useEffect(() => {
    new Glide("." + css.root, {
      gap: 30,
      autoplay: 3000,
    }).mount();
    () => {};
  }, [images]);
  
  return (
    <div className={cn(css.root, "glide")}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {images.map((image, index) => {
             const img =  image.vimeo ? (
              <Video url={image.vimeo} autoplay loop />
            ) : (
              <Image {...image} ratio={1.66} fit />
            )
            
            return (
              <li className="glide__slide" key={index}>
                {image.url ? <a href={image.url}>{img}</a> : img}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default Carousel;
