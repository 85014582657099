let data = false;
if (document.querySelector("#site_data")) {
  data = JSON.parse(document.querySelector("#site_data").textContent);
}
export default data;
export const work = data.work;
export const about = data.about;
export const home = data.home;
export const site = data.site;

export function findProjectBySlug(slug) {
  return work.projects.find((p) => {
    return p.slug == slug;
  });
}
