import { Link } from "react-router-dom";
import cn from "classnames";

import Image from "../components/Image";
import Video from "../components/Video";

import css from "./ProjectCard.module.css";

export const ProjectCard = ({
  thumb,
  slug,
  title,
  subtitle,
  category,
  color,
  className,
}) => {
  const section = category && category.includes("Art") ? "art" : "design";

  const to = `/${section}/${slug}`;
  return (
    <div className={cn(css.root, className)}>
      <figure>
        {thumb && (
          <Link to={to}>
            {thumb.vimeo ? (
              <Video url={thumb.vimeo} autoplay loop muted noLink />
            ) : (
              <Image
                {...thumb}
                color={color}
                srcSetSizes="(max-width: 700px) 90vw,
              (max-width: 1100px) 40vw,
              30vw"
              />
            )}
          </Link>
        )}
      </figure>
      <div className={css.info}>
        <h2>
          <Link to={to}>{title}</Link>
        </h2>
        <p>
          <Link to={to}>{subtitle}</Link>
        </p>
      </div>
    </div>
  );
};

export default ProjectCard;
