import React from "react";
import classnames from "classnames";
import ReactPlayer from "react-player";
import * as css from "./Video.module.css";

const youtubeConfig = ({ loop, autoPlay }) => ({
  playerVars: {
    controls: 0,
    autoplay: autoPlay ? 1 : 0,
    loop: loop ? 1 : 0,
    modestbranding: 1,
    rel: 0,
    playsinline: 0,
  },
});

const vimeoConfig = ({ loop, autoPlay }) => ({
  playerOptions: {
    autoplay: autoPlay,
    autopause: false,
    loop,
    controls: false,
    portrait: false,
    title: false,
  },
});

const PlayIcon = () => (
  <div className={css.playIconWrapper}>
    <div className={css.playIcon} />
  </div>
);

export const Video = ({
  url,
  poster,
  muted,
  loop,
  autoplay,
  noLink,
  className,
  ...otherProps
}) => {
  return (
    <div
      className={classnames(css.root, className, { [css.noLink]: noLink })}
      {...otherProps}
    >
      <div className={css.container}>
        <ReactPlayer
          muted={muted}
          light={poster}
          className={css.reactPlayer}
          url={url}
          playIcon={<PlayIcon />}
          config={{
            youtube: youtubeConfig({ loop, autoPlay: autoplay || !!poster }),
            vimeo: vimeoConfig({ loop, autoPlay: autoplay || !!poster }),
          }}
        />
      </div>
    </div>
  );
};

export default Video;
