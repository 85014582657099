import { Link, useParams } from "react-router-dom";
import cn from "classnames";

import { useProject } from "../utils/hooks";

import Image from "../components/Image";
import Video from "../components/Video";

import css from "./Project.module.css";

export const Project = (props) => {
  const params = useParams();

  const project = useProject(params.project);
  if (!project) return <h1>Sorry no projecto acá</h1>;
  const [cover, ...images] = project.images;

  const { id, title, subtitle, text, field, tags, team } = project;
  const related = project.related.map((r) => useProject(r));

  return (
    <article className={css.root} key={id}>
      <section className={css.cover}>
        {cover.vimeo ? (
          <Video url={cover.vimeo} poster={cover.src} />
        ) : (
          <Image {...cover} />
        )}
      </section>

      <section className={css.info}>
        <div className={css.text}>
          <h1>{title}</h1>
          {subtitle && <h2>{subtitle}</h2>}
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
        <div className={css.meta}>
          <div>
            <h3>Tags</h3>
            <p>{tags.concat(field).join(", ")}</p>
          </div>
          <div>
            <h3>Credits</h3>
            <div dangerouslySetInnerHTML={{ __html: team }} />
          </div>
        </div>
      </section>
      <section className={css.images}>
        {images.map((image, index) => {
          const srcSetSizes =
            image.image_position == "right" || image.image_position == "left"
              ? "(max-width: 700px) 100vw, (min-width: 701px) 50vw"
              : "100vw";
          return (
            <figure
              key={index}
              className={cn(css.image, css[image.image_position])}
            >
              {image.vimeo ? (
                <Video url={image.vimeo} poster={image.src} />
              ) : (
                <Image {...image} srcSetSizes={srcSetSizes} />
              )}
            </figure>
          );
        })}
      </section>
      <section id="related-projects" className={css.relatedProjects}>
        <h2>Related Projects</h2>
        <div className={css.relatedProjectsList}>
          {related.map((rel, index) => {
            if (!rel) return null;
            const url = `/${rel.category[0].toLowerCase()}/${rel.slug}`;
            return (
              <div key={index} className={css.relatedProject}>
                <figure className={cn(css.relatedImage)}>
                  <Link to={url}>
                    <Image
                      {...rel.thumb}
                      fit
                      ratio={1.6}
                      srcSetSizes="(max-width: 600px) 90vw, 33vw"
                    />
                  </Link>
                </figure>
                <h3>
                  <Link to={url}>
                    {rel.title} <span>{rel.client}</span>
                  </Link>
                </h3>
              </div>
            );
          })}
        </div>
      </section>
    </article>
  );
};
export default Project;
