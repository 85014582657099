import React, { useEffect, useState } from "react";
import classnames from "classnames";
import moment from "moment-timezone";
import css from "./Clock.module.css";

const getTime = (timezone) => {
  const t = moment().tz(timezone);
  return {
    time: t.format("LTS"),
    minute: t.format("m"),
    hour: t.format("h"),
    second: t.format("s"),
  };
};

const Clock = ({ timezone, city }) => {
  const [time, setTime] = useState(getTime(timezone));
  useEffect(() => {
    const timer = setInterval(() => {
      setTime(getTime(timezone));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [timezone]);

  return (
    <div className={css.root}>
      <div className={css.dial}>
        <div
          className={css.hour}
          style={{
            transform: `rotate(${180 + (time.hour * 360) / 12}deg)`,
          }}
        />
        <div
          className={css.minute}
          style={{
            transform: `rotate(${180 + (time.minute * 360) / 60}deg)`,
          }}
        />
        <div
          className={css.second}
          style={{
            transform: `rotate(${180 + (time.second * 360) / 60}deg)`,
          }}
        />
      </div>

      <h3 className={css.city}>{city}</h3>
    </div>
  );
};
//
// class OldClock {
//   constructor(props) {
//     super(props);
//     this.state = this.getTime();
//     this.tick();
//   }
//   componentWillUnmount() {
//     clearTimeout(this.timer);
//   }
//   tick() {
//     this.timer = setTimeout(() => {
//       this.setState(this.getTime());
//       this.tick();
//     }, 1000);
//   }
//   getTime() {
//     let time = moment.tz(this.props.timezone);
//     return {
//       time: time.format("LTS"),
//       minute: time.format("m"),
//       hour: time.format("h"),
//       second: time.format("s"),
//     };
//   }
//   render() {
//     return (
//       <div
//         className={classnames("clock", {
//           [this.props.className]: this.props.className,
//         })}
//       >
//         <div className="dial">
//           <div
//             className="minute"
//             style={{
//               transform: `rotate(${180 + (this.state.minute * 360) / 60}deg)`,
//             }}
//           />
//           <div
//             className="hour"
//             style={{
//               transform: `rotate(${180 + (this.state.hour * 360) / 12}deg)`,
//             }}
//           />
//           <TextCircle
//             style={{ fontSize: "inherit" }}
//             rotate={180 + (this.state.second * 360) / 60}
//             text={this.props.text}
//           />
//         </div>
//         <div>
//           <div className="label">{this.props.city}</div>
//         </div>
//       </div>
//     );
//   }
// }

export default Clock;
