import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Layout from "./components/Layout";
import Index from "./pages/Index";
import ProjectsIndex from "./pages/ProjectsIndex";
import Project from "./pages/Project";
import About from "./pages/About";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Index />} />
          <Route path="design" element={<ProjectsIndex key="design" />} />
          <Route path="art" element={<ProjectsIndex key="art" />} />
          <Route path="art/:project" element={<Project />} />
          <Route path="design/:project" element={<Project />} />
          <Route path="about" element={<About />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
