import { useMemo, useState, useEffect } from "react";
import { filter } from "lodash";

import data, { findProjectBySlug, about, home, site } from "./data";

export const useData = (key) => {
  return data[key] || data;
};

export const useProjects = (type) => {
  return useMemo(() => {
    const projects = data.work.projects.filter(
      (p) => p.category && p.category.map((c) => c.toLowerCase()).includes(type)
    );

    return projects;
  }, []);
};

export const useCarousel = (type) => {
  return data[type].images;
};

export const useProject = (slug) => {
  return useMemo(() => findProjectBySlug(slug), [slug]);
};

export const useAbout = () => {
  return about;
};

export const useHome = () => {
  return home;
};

export const useSite = () => {
  return site;
};
