import { useHome } from "../utils/hooks";
import { Link } from "react-router-dom";
import { splitTitle } from "../utils";

import Image from "../components/Image";

import css from "./Index.module.css";

const Index = () => {
  const home = useHome();

  return (
    <section>
      <h1 className={css.title}>
        {splitTitle("Simón Sepúlveda Studio", css.cap)}
      </h1>
      <div className={css.row}>
        <Link className={css.link} to="/design">
          Design
        </Link>
        <figure className={css.photo}>
          <Image {...home.images[0]} />
        </figure>

        <Link className={css.link} to="/art">
          Art
        </Link>
      </div>
    </section>
  );
};

export default Index;
