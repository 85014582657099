import React, { useEffect } from "react";
import { Outlet, useMatch, useLocation } from "react-router-dom";
import cn from "classnames";
import { useSite } from "../utils/hooks";
import Header from "./Header";

import css from "./Layout.module.css";

import { Head, Arrows } from "../icons/Icons";

const Layout = () => {
  const invert = !useMatch("art/*");
  const isIndex = useMatch("/");
  const { pathname } = useLocation();
  const site = useSite();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={cn(css.root, { [css.invert]: invert })}>
      {!isIndex && <Header />}
      <main>
        <Outlet />
      </main>
      <footer className={css.footer}>
        <p>
          {site.copyright &&
            site.copyright.replace(/{thisYear}/g, new Date().getFullYear())}
        </p>
      </footer>
    </div>
  );
};
export default Layout;
